<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio evento</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="event_container">
                    <div class="event_title">
                        {{ event.events_calendar_title }}
                    </div>
                    <div class="image_container">
                        <div v-if="event.events_calendar_photo" class="photo">
                            <ion-img :src="setImageUrl(event.events_calendar_photo)"></ion-img>
                        </div>
                    </div>

                    <div class="field">
                        <div class="title">Inizio</div>
                        <div class="value">
                            {{ dateTimeFormat(event.events_calendar_date_start) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Fine</div>
                        <div class="value">
                            {{ dateTimeFormat(event.events_calendar_date_end) }}
                        </div>
                    </div>
                    <div class="field field_description">
                        <div class="title">Descrizione</div>
                        <div class="value">
                            {{ event.events_calendar_description ? `${event.events_calendar_description}` : "-" }}
                        </div>
                    </div>
                </div>

                <div v-if="comunicataPartecipazione == true" class="partecipazione">{{ string }}</div>
                <div v-else class="event_actions">
                    <div class="btn_partecipa" @click="setValue(1)">Partecipo</div>
                    <div class="btn_rifiuta" @click="setValue(0)">Non partecipo</div>
                </div>

                <div class="container_adesioni">
                    <div class="btn_adesioni" @click="openAdesioniDetail()">Vedi adesioni</div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    IonImg,
    modalController,
} from "@ionic/vue";
import { defineComponent, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import { arrowBackOutline, arrowForwardCircle } from "ionicons/icons";

import moment from "moment";

import { dateFormat, dateFormatWithMinutes } from "@/services/utils";
import { openToast } from "@/services/toast";

import apiEventi from "@/services/eventi";

import EventAdesioni from "@/components/event/EventAdesioni.vue";

export default defineComponent({
    name: "EventDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        IonImg,
    },
    setup(props, context) {
        const route = useRoute();
        const id = route.params.id; //richiesta_id by route params
        const router = useRouter();
        const loggedUserId = JSON.parse(localStorage.getItem("userInfoAssitech")).dipendenti_user_id;
        const loggedDipendenteId = JSON.parse(localStorage.getItem("userInfoAssitech")).dipendenti_id;

        const loading = ref(false);
        const successResponse = ref(false);

        //console.log(props.data);
        const event = ref([]);
        event.value = { ...props.data };
        console.log(event.value);

        /**
         * ! Open event adesioni detail page
         */
        async function openAdesioniDetail() {
            //console.log(event.value);
            const modal = await modalController.create({
                component: EventAdesioni,
                componentProps: {
                    data: event.value,
                },
            });
            return modal.present();
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };
        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * Format datetime to DD/MM/YY HH:mm
         */
        function dateTimeFormat(dateTime) {
            if (dateTime && moment.isDate(new Date(dateTime))) {
                return moment(dateTime).format("DD/MM/YYYY HH:mm");
            } else {
                return "-";
            }
        }

        /**
         * ! Set correct image path
         */
        function setImageUrl(path) {
            //console.log(path);
            return `${process.env.VUE_APP_BASE_URL}/uploads/${path}`;
        }

        async function setValue(status) {
            const eventId = event.value.events_calendar_id;

            const data = new FormData();
            data.append("events_calendar_participant_user", loggedUserId); //logged user
            data.append("events_calendar_participant_event_id", eventId); // event id
            data.append("events_calendar_participant_confirm", status);

            apiEventi
                .setPartecipazione(data)
                .then((response) => {
                    console.log(response);
                    if (response.data.status == 0) {
                        openToast(response.data.txt, "toast_danger");
                    } else {
                        const res = response.data.data;
                        console.log(res);
                        successResponse.value = true;
                        closeModalOnSubmit(successResponse, res);
                    }
                })
                .catch((error) => {
                    //console.error(error);
                    openToast("Errore durante la comunicazione della partecipazione", "toast_danger");
                });
        }

        /**
         * ! Controlla se ho comunicata la partecipazione/non partecipazione
         * ! per nascondere i button e mostrare scritta di conseguenza (hai comunicato che ...)
         */
        const comunicataPartecipazione = ref(false);
        const string = ref("");
        async function checkPartecipazione() {
            const eventId = event.value.events_calendar_id;
            loading.value = true;

            apiEventi
                .checkAdesione(eventId, loggedUserId)
                .then((response) => {
                    console.log(response);
                    if (response.status != 200) {
                        openToast("Errore durante la verifica della partecipazione", "toast_danger");
                    } else if (response.data.data.length != 0) {
                        comunicataPartecipazione.value = true;
                        const res = response.data.data[0];
                        if (res.events_calendar_participant_confirm == 1) {
                            string.value = "Hai comunicato di partecipare all'evento";
                        } else {
                            string.value = "Hai comunicato di non partecipare all'evento";
                        }
                    }

                    /*  else {
                        const res = response.data.data;
                        console.log(res);
                        successResponse.value = true;
                        closeModalOnSubmit(successResponse, res);
                    } */
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la verifica della partecipazione", "toast_danger");
                })
                .finally(() => {
                    loading.value = false;
                });
        }

        onMounted(() => {
            checkPartecipazione();
        });

        return {
            loading,
            dateFormat,
            dateFormatWithMinutes,
            arrowBackOutline,
            closeModal,
            arrowForwardCircle,
            event,
            setImageUrl,
            dateTimeFormat,
            setValue,
            openAdesioniDetail,
            string,
            comunicataPartecipazione,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-button {
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-primary {
    background: var(--ion-color-primary, #3880ff) !important;
    padding: 8px;
    color: #ffffff;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

/* lead v2*/
.event_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.event_container .event_title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #475569;
    margin-bottom: 8px;
}
.event_container .city {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 8px;
}
.event_container .image_container {
    margin-bottom: 16px;
}
.event_container .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.event_container .field_description {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.event_container .field_description .title {
    width: 100%;
    margin-bottom: 4px;
}
.event_container .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px 6px;
    border-bottom: none;
}
.event_container .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}

/** lead standard */
.lead {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.lead .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.lead .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    border-bottom: none;
}
.lead .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}
.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}
.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.value .badge_success {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
}
.value .badge_warning {
    background-color: #f97316;
    background-color: rgb(254 243 199);
    color: rgb(245 158 11);
}
.value .badge_danger {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
}
.mobile_link {
    color: #086fa3;
    text-decoration: none;
}

.photo {
    display: flex;
    justify-content: center;
    align-items: center;
}
ion-img::part(image) {
    max-height: 250px;
}

.event_actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
}
.btn_partecipa,
.btn_rifiuta {
    width: 48%;
    display: flex;
    justify-content: center;
    padding: 12px 24px;
    color: #ffffff;
    font-weight: 500;
    border-radius: 8px;
}
.btn_partecipa {
    background-color: #16a34a;
    /* color: #16a34a;
    background-color: #bbf7d0; */
}
.btn_rifiuta {
    background-color: #dc2626;
    /* color: #dc2626;
    background-color: #fecaca; */
}

.container_adesioni {
    width: 100%;
    margin-top: 32px;
}
.btn_adesioni {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px 24px;
    color: #ffffff;
    background-color: #086fa3;
    font-weight: 500;
    border-radius: 8px;
    text-transform: uppercase;
}

.partecipazione {
    margin-top: 32px;
    font-size: 16px;
    font-weight: 500;
    color: #475569;
    text-align: center;
}
</style>
